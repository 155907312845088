<template>
  <div class="pay-result-page">
    <div class="pay-result-img"></div>
    <div class="pay-result-text">
      您的支付申请已提交，预计30分钟内有还款结果，请前往账单中查看
    </div>
    <div class="pay-result-btn" @click="JumpLink">确认</div>
  </div>
</template>

<script>
import {toRefs, reactive} from 'vue'
import {useRouter} from 'vue-router'
export default {
  name: 'Index',
  setup() {
    const Router = useRouter()
    const state = reactive({phoneType: null})
    // 还款完成
    const JumpLink = () => {
      Router.go(-4)
    }
    return {
      ...toRefs(state),
      JumpLink
    }
  }
}
</script>
<style scoped lang='less' src='./index.less'></style>
